<template>
    <b-modal id="files-modal"
                ref="fileManagement"
                ok-title="Применить"
                cancel-title="Отменить"
                @cancel="onModalClose"
                ok-variant="success"
                :ok-disabled="!dataChanged"
                @ok="() => { $emit('setNewFileHash', currItem.id, newFileCodes); dataChanged = false}"
                size="md"
                centered
                modal-class="modal-table modal-files"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
    >
        <template #modal-header="{ close }">
            <div class="title">Управление файлами</div>
            <!-- <i class="icon icon-close" @click="close()"></i> -->
        </template>
        
        <div class="modal-search">
            <b-form-group>
                <b-form-select v-model="selectedCategory" :options="categoryFiles" text-field="name_ru"></b-form-select>
            </b-form-group>
            <b-form-group>
                <!-- Поле ввода текста для поиска -->
                <b-form-input ref="searchTextField" v-model="searchText" placeholder="Поиск" type="text"/>
                <!-- Кнопка очистки текста -->
                <i class="icon icon-close clear-button" @click="() => { searchText = '' }"/>
            </b-form-group>  
        </div>
        
        <div class="table-container modal-table-card mb-3">
            <p class="file-cat-name">Прикрепленные файлы:</p>
            <p class="file-not-found" v-show="!searchedAttachedFiles.length">Файлы не найдены</p>

            <div class="file-info" v-for="item in searchedAttachedFiles" :key="item.id">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        v-model="item.row_id"
                        name="checkbox-1"
                        :value="item.id"
                        :unchecked-value="null"
                        :disabled="!variantAttribute"
                        @change="onFilesListChanged"
                    >
                    </b-form-checkbox>
                    <p class="name">
                        <file-icons :filename="item.file_name"></file-icons>
                        <span style="cursor: pointer" @click="()=>$emit('triggDownloadFile', item.file_name)">{{item.file_name}}</span>
                    </p>
                    <p class="size">{{item['size']}}</p>
                    <p class="size">({{getCatName(item.category)}})</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="table-container modal-table-card mb-3">
            <p class="file-cat-name">Другие файлы:</p>
            <p class="file-not-found" v-show="!searchedAllFiles.length">Файлы не найдены</p>

            <div class="file-info" v-for="item in searchedAllFiles" :key="item.id">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        v-model="item.row_id"
                        name="checkbox-1"
                        :value="item.id"
                        :unchecked-value="null"
                        :disabled="!variantAttribute"
                        @change="onFilesListChanged"
                    >
                    </b-form-checkbox>
                    <p class="name">
                        <file-icons :filename="item.file_name"></file-icons>
                        <span style="cursor: pointer" @click="()=>$emit('triggDownloadFile', item.file_name)">{{item['file_name']}}</span>
                    </p>
                    <p class="size">{{item['size']}}</p>
                    <p class="size">({{getCatName(item.category)}})</p>
                </div>
            </div>
        </div>
        <b-button
                size="sm"
                variant="primary"
                @click="$emit('showFileUploadModal')"
                :disabled="!variantAttribute"
            >
                Загрузить
        </b-button>

    </b-modal>
</template>

<script>
import FileUpdownMixin from '../mixins/file-updown-mixin';
import FileIcons from './file-icons.vue';

export default {
    name: "modal-files-management",
    components: { FileIcons },
    props: {
        allFiles: Array,
        variantAttribute: Boolean,
    },
    mixins: [FileUpdownMixin],
    data() {
        return {
            selectedCategory: 'All',
            searchText: '',
            currItem: null,
            newFileCodes: null,
            dataChanged: false,
            newFileAdded: false
        }
    },
    watch: {
        searchedAllFiles() {
            if (!this.searchedAttachedFiles) return;
            for (const file of this.searchedAttachedFiles) {                
                if (file.new_file) {
                    this.onFilesListChanged();
                    return;
                }
            }
            this.dataChanged = false;
        }
    },
    methods: {
        showModal(item) {            
            this.currItem = item;
            this.newFileCodes = item.file_hash;
            const allCategories = {
                name_ru: "Все категории",
                value: 'All'
            }
            this.categoryFiles[0] = allCategories;
            if (this.allFiles.length) {
                this.allFiles.forEach(item => {
                    if (item.new_file) {
                        this.$set(item, 'new_file', false);
                    }
                })
            }
            this.$refs.fileManagement.show();
        },

        getCatName(code) {
            const currCat = this.categoryFiles.find(item => item.value === code);
            return currCat ? currCat.name_ru : null
        },

        onFilesListChanged() {
            const attachedFiles = [];
            this.searchedAttachedFiles.forEach(item => {
                attachedFiles.push(this.stringToUnicode(item.category + item.file_name))
            });
            const codes = attachedFiles.join(';')
            this.newFileCodes = codes;
            this.dataChanged = true;
        },
        
        stringToUnicode(string) {
            let chr = '';
            if (string.length === 0) return hash;
            for (let i = 0; i < string.length; i++) {
                chr += string.charCodeAt(i); 
            }
            return chr;
        },

        onModalClose(e) {
            if (this.dataChanged) {
                e.preventDefault();
                this.onFilesModalClose();
            } else {
                this.currItem = null;
                this.dataChanged = false;
            }
        }, // отчистка 'goods-modal' при закрытии без сохранения

        onFilesModalClose() {
            this.$bvModal.msgBoxConfirm(
                'Внесенные изменения будут потеряны!',
                {   
                    title: 'Внимание!',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Продолжить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.$bvModal.hide('files-modal')
                        this.currItem = null;
                        this.dataChanged = false;
                    } 
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка закрытия модального окна', error.toString());
                });
        },
    },
    computed: {
        attachedFiles() {
            const newArr =  this.allFiles.map(item => {
                if (this.currItem && this.currItem.file_hash && this.currItem.file_hash.includes(this.stringToUnicode(item.category + item.file_name))) {
                    item.row_id = item.id;
                } else if (item.new_file) {
                    item.row_id = item.id;
                } else item.row_id = null;
                return item;
            })
            return newArr
        },
        searchedAllFiles() {
            if (!this.attachedFiles) return [];
            // let sortedFiles = this.attachedFiles;
            const sortedFiles = this.attachedFiles.filter(item => {
                let result = true;
                if (this.selectedCategory !== "All") {
                    result = result && item.category === this.selectedCategory;
                };
                if (this.searchText.length) {
                    result = result && item.file_name.toLowerCase().includes(this.searchText.toLowerCase());
                };
                result = result && !item.row_id;
                return result;
            });
            return sortedFiles.sort((a, b) => a.file_name > b.file_name ? 1 : -1);
        },
        searchedAttachedFiles() {
            if (!this.attachedFiles) return [];
            // let sortedFiles = this.attachedFiles;
            const sortedFiles = this.attachedFiles.filter(item => {
                let result = true;
                if (this.selectedCategory !== "All") {
                    result = result && item.category === this.selectedCategory;
                };
                if (this.searchText.length) {
                    result = result && item.file_name.toLowerCase().includes(this.searchText.toLowerCase());
                };
                result = result && item.row_id;
                return result;
            });
            return sortedFiles.sort((a, b) => a.file_name > b.file_name ? 1 : -1);
        },
    },
}

</script>

<style scoped>
.file-cat-name {
    font-size: 0.9rem;
}
.file-not-found {
    font-size: 0.8rem;
    font-style: italic;
    color: #A8B5CD;
    margin-left: 2rem;
}
.serch-group {
    margin-top: 10px;
    display: flex;
}
.serch-group icon {
    position: absolute;
    right: 25px;
    margin-top: 5px;
}
</style>